<template>
  <slot></slot>
</template>

<script>
const CardContent = {
  name: "brCardContent",
  props: {},
}

export default CardContent
</script>
