<template>
  <slot></slot>
</template>

<script>
const CardFooter = {
  name: "brCardFooter",
  props: {},
}

export default CardFooter
</script>
